
import sanitizeText from "@/utils/sanitize-text"

export default {
    name: "TextBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        classes() {
            return {
                [this.blok.fontweight]: !!this.blok.fontweight,
                [this.blok.typography]: !!this.blok.typography,
                "text-center": !!this.blok.center,
                "font-italic": !!this.blok.italic,
                [this.blok.customClasses]: !!this.blok.customClasses,
                "text-block": !!this.blok.block,
                "mx-auto": this.blok.center && this.blok.maxWidth,
                "custom--text": !!this.blok.customTheme,
                "white--text": this.blok.whiteText,
            }
        },
        tag() {
            return this.blok.htmlTag ?? "p"
        },
        margin() {
            const spacings = []
            const blok = this.blok
            spacings.push(this.toString(blok.mt, "t"))
            spacings.push(this.toString(blok.mr, "r"))
            spacings.push(this.toString(blok.mb, "b"))
            spacings.push(this.toString(blok.ml, "l"))
            spacings.push(this.toString(blok.md_mt, "t", "md"))
            spacings.push(this.toString(blok.md_mr, "r", "md"))
            spacings.push(this.toString(blok.md_mb, "b", "md"))
            spacings.push(this.toString(blok.md_ml, "l", "md"))
            return spacings.filter((n) => n)
        },
        style() {
            const style = {}
            if (this.blok.maxWidth) {
                style.maxWidth = `${this.blok.maxWidth}px`
            }
            if (this.blok.fontSize) {
                style.fontSize = this.blok.fontSize
            }
            if (this.blok.customStyles) {
                for (const entry of this.blok.customStyles.split(";")) {
                    const [key, value, ...rest] = entry.split(":")
                    if (rest?.length > 0) {
                        console.warn(
                            "Too many values in CSS string:",
                            entry,
                            rest
                        )
                    }
                    style[key] = value
                }
            }
            return style
        },
        formattedText() {
            if (!this.blok.text) return
            if (this.blok.mobileText && this.$breakpoint.smAndDown) {
                return sanitizeText(this.blok.mobileText).replace(
                    /\*([^*]+?)\*/g,
                    "<b>$1</b>"
                )
            }
            return sanitizeText(this.blok.text).replace(
                /\*([^*]+?)\*/g,
                "<b>$1</b>"
            )
        },
    },
    methods: {
        toString(value, direction, breakpoint) {
            if (!value) return
            const usePadding = value > 0 && this.blok.padding
            let prefix = usePadding ? "p" : "m"
            prefix += direction ?? "a"
            prefix += "-"
            if (breakpoint) {
                prefix += `${breakpoint}-`
            }
            if (value > 0) return prefix + value
            return prefix + "n" + Math.abs(value)
        },
    },
}
